// src/apolloClient.js
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { HttpLink } from 'apollo-link-http'

const client = new ApolloClient({
    link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
      }),
      fetchOptions: {
        mode: 'no-cors',
      },
      cache: new InMemoryCache(),
});

export default client;
