// src/TokenPrice.js
import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import client from './apolloClient';

const GET_TOKEN_PRICE = gql`
  query GetTokenPrice($id: String!) {
    token(id: $id) {
      id
      symbol
      name
      derivedETH
    }
  }
`;

const TokenPrice = ({ tokenId }) => {
  const { loading, error, data } = useQuery(GET_TOKEN_PRICE, {
    variables: { id: tokenId },
    client: client,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const token = data.token;
  const ethPrice = 2000; // Assuming ETH price is $2000, you should fetch this from a reliable source

  return (
    <div>
      <h1>{token.name} ({token.symbol})</h1>
      <p>Price in ETH: {token.derivedETH}</p>
      <p>Price in USD: ${(token.derivedETH * ethPrice).toFixed(2)}</p>
    </div>
  );
};

export default TokenPrice;
